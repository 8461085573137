export const TRANSACTIONS = [
  { address: "Matic Investments", value: "10,00" },
  { address: "Matic Claim", value: "10,00" },
  { address: "BUSD Investments", value: "10,00" },
  { address: "BUSD Claim", value: "10,00" },
  { address: "BNB Investments", value: "10,00" },
  { address: "BNB Claim", value: "10,00" },
  { address: "Refund amount", value: "10,00" },
  { address: "Claimed by", value: "10,00" },
  { address: "Claim status", value: "10,00" },
  { address: "Claim time", value: "10,00" },
];

export const SFREFUND_ADDRESS = '0x4CbfdE7de17bc6E2c3F9951101eB067870e4B1e7'
export const RPC = "https://data-seed-prebsc-1-s2.binance.org:8545";
export const CHAIN_ID = 97 
export const chain_id = '0x61' 
