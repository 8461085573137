/* eslint-disable @typescript-eslint/no-explicit-any */
import SFRefund from "utils/contracts/SFRefund.json";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { KEYS } from "keys/keys";
import { QueryClient } from "@tanstack/query-core";
import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import { showToast } from "./helper";
import { CHAIN_ID, RPC, SFREFUND_ADDRESS, chain_id } from "./const";

const chainId = chain_id;
const networkName = "Smart Chain - Testnet";
const nativeCurrencyName = "BNB";
const nativeCurrencySymbol = "BNB";
const decimals = 18;
const scanUrl = "https://bscscan.com/";

let web3 = new Web3(RPC);

export const addBSCNetwork = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainId,
          rpcUrls: [RPC],
          chainName: networkName,
          nativeCurrency: {
            name: nativeCurrencyName,
            symbol: nativeCurrencySymbol,
            decimals: decimals,
          },
          blockExplorerUrls: [scanUrl],
        },
      ],
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const connectAccount = async (client: QueryClient) => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          chainid: CHAIN_ID,
          rpc: {
            CHAIN_ID: RPC,
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    const provider = await web3Modal.connect();
    web3 = new Web3(provider);
    const chainid = await web3.eth.getChainId();

    if (chainid != CHAIN_ID) {
      await addBSCNetwork();
      return showToast("error", `Please connect with ${networkName}!`);
    }
    const accounts = await web3.eth.getAccounts();
    client.setQueryData([KEYS.WALLET_ADDRESS], accounts[0]);
    if (accounts[0]) console.log("success", "wallet connected successfully!");
    provider.on("accountsChanged", (accounts: any) => {
      client.setQueryData([KEYS.WALLET_ADDRESS], accounts[0]);
    });

    return accounts[0];
  } catch (error) {
    console.log({
      isOpen: false,
      message: "",
    });
    throw error;
  }
};

export const disconnectWallet = async () => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          chainid: CHAIN_ID,
          rpc: {
            CHAIN_ID: RPC,
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    await web3Modal.clearCachedProvider();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSFRefundContract = () => {
  const contract: Contract = new web3.eth.Contract(
    SFRefund["abi"] as any,
    SFREFUND_ADDRESS
  );

  return contract;
};

export const getInvestorStats = async (walletAddress: string): Promise<any> => {
  try {
    if (walletAddress && web3) {
      const SFRefund: Contract = getSFRefundContract();

      const {
        bnbData,
        busdData,
        maticData,
        totalRemainingInBUSD,
        isClaimable,
        isClaimed,
      } = await SFRefund.methods.getInvestor(walletAddress).call();

      const bnbTotalInvestment = bnbData?.map((value: string, i: number) =>
        web3.utils.fromWei(value, "ether")
      );
      const busdTotalInvestment = busdData?.map((value: string, i: number) =>
        web3.utils.fromWei(value, "ether")
      );
      const maticTotalInvestment = maticData?.map((value: string, i: number) =>
        web3.utils.fromWei(value, "ether")
      );
      return {
        coinStats: [
          ["BNB",...bnbTotalInvestment],
          ["BUSD",...busdTotalInvestment],
          ["Matic",...maticTotalInvestment],
        ],
        totalRemainingInBUSD: web3.utils.fromWei(totalRemainingInBUSD, "ether"),
        isClaimable,
        isClaimed,
      };
    } else {
      return {
        coinStats: [],
      };
    }
  } catch (err: any) {
    console.log(err?.message);
    throw err;
  }
};

export const claimReward = async (walletAddress: string) => {
  try {
    if (!walletAddress) {
      showToast(
        "error",
        "Please connect your wallet first",
        "You need to connect your wallet before minting"
      );
      return;
    }

    const SFRefund: Contract = getSFRefundContract();

    const trx = await SFRefund.methods
      .claimRefund()
      .send({ from: walletAddress });

    console.log("trx", trx);

    showToast(
      "success",
      "Claimed successfully",
      "You have successfully claimed the referral"
    );

    return trx;
  } catch (err: any) {
    console.log(err);
    showToast("error", "Something went wrong", err?.message);
    return err;
  }
};
