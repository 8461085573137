import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Flex,
  Heading,
  Button,
  Text,
  Table,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";
import { EarnXContext } from "App";
import {
  claimReward,
  connectAccount,
  disconnectWallet,
  getInvestorStats,
  getSFRefundContract,
} from "utils/bc-helper";
import { useQueryClient } from "@tanstack/react-query";
import { UserStats } from "utils/types/global";
import Contract from "web3-eth-contract";
import { paste } from "@testing-library/user-event/dist/paste";
// import { FromIcon, ToIcon } from 'assets/icons'
export default function Transactions() {
  const client = useQueryClient();
  const [userStats, setUserStats] = useState<UserStats | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPause, setIsPause] = useState<boolean | null>();

  const { walletAddress, setWalletAddress } = useContext(EarnXContext);

  const handleConnectWallet = async () => {
    if (!walletAddress) {
      const wallet = await connectAccount(client);
      if (wallet) {
        setWalletAddress(wallet);
        const stats = await getInvestorStats(wallet);
        if (stats) setUserStats(stats);
      }
    } else {
      await disconnectWallet();
      setWalletAddress("");
    }
  };

  const handleClaim = async () => {
    setIsLoading(true)
    const isClaim = await claimReward(walletAddress)
    if(isClaim) setIsLoading(false)
  }

  const checkPaused = async () => {
    const SFRefund: Contract = getSFRefundContract();
    const paused = await SFRefund.methods.paused().call()
    if(paused || paused === false) setIsPause(paused)
  };

  useEffect(() => {
    checkPaused()
  },[])


  return (
    <Container maxW="1280px" py="30px" position="relative" zIndex="1">
      <Flex justify="space-between">
        <Heading as="h2" mb="45px" fontSize={30}>
          SF ReFund
        </Heading>
        <Button bg="black" w="200px" size="lg" onClick={handleConnectWallet}>
          {walletAddress ? "Disconnect Wallet" : "Connect Wallet"}
        </Button>
      </Flex>
      <Text
        textAlign="center"
        border="1px solid #000"
        p="6px 20px"
        maxW="max-content"
        mx="auto"
        rounded="5px"
      >
        {walletAddress
          ? walletAddress?.replace(walletAddress?.slice(5, 37), "****")
          : "Connect your wallet to see address"}
      </Text>
      {walletAddress && <><Box mt="60px" border="1px solid #000" p="15px" rounded="8px">
        <Table>
          <Tr>
            <Th>Coin</Th>
            <Th>Total Investment</Th>
            <Th> Total Claimed</Th>
            <Th>Total Capital Withdrawn</Th>
            <Th>Total Remaining</Th>
          </Tr>
          {userStats?.coinStats?.map( (item) => (
            <Tr
              css={`
                :last-child td {
                  border: none;
                }
              `}
            >
              {item?.map( (label, i) => (<Td fontWeight={i === 0 ? 800 :400}>{label}</Td>))}
            </Tr>
          ))}
        </Table>
      </Box>
      <Text mt="20px"><strong>Total Remaining BUSD:</strong> {userStats?.totalRemainingInBUSD}</Text></>}
      {(walletAddress && userStats?.isClaimable && !userStats?.isClaimed && !isPause) ? (
        <Button bg="black" isLoading={isLoading} onClick={handleClaim} mt="30px" display="flex" mx="auto" w="200px">
          Claim Now
        </Button>
      ) : null}
    </Container>
  );
}
